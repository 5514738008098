import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useUiStore } from "../hooks";
import { useCheckoutStore } from "../hooks/useCheckoutStore";
import omitBy from "lodash-es/omitBy";

const get = async (url) => {
  const res = await fetch(url);
  const content = await res.json();
  if (res.ok) return content;
  else throw new Error(res.message, { cause: res.status });
};

const useAuthorizedQuery = (queryKey, queryFn, options) => {
  const { locale } = useParams();
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  return useQuery({
    queryKey,
    queryFn,
    ...options,
    // refetchOnMount: false,
    // refetchOnReconnect: true,
    onError: (error) => {
      toast({
        title: t("warning"),
        description: error.message,
        status: "error",
      });
      if (error.cause === 404) return navigate(`/${locale}/not-found`);
      if (error.cause >= 500) return navigate(`/${locale}/error`);
      if (error.cause === 401) {
        const redirect = encodeURIComponent(location.pathname);
        return navigate(`/${locale}/accounts/sign-in?redirect=${redirect}`);
      } else if (typeof options.onError === "function") options.onError(error);
    },
  });
};

export const useFetchMenu = (opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["header_menu"],
    () =>
      get(
        `/storefront/home_pages/header_menu.json?` +
        new URLSearchParams({ locale })
      ),
    {
      initialData: {
        main_items: [],
      },
      ...opts,
    }
  );
};

export const useFetchHome = (id, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["home_pages", id],
    () =>
      get(
        `/storefront/home_pages/${id}.json?` + new URLSearchParams({ locale })
      ),
    {
      initialData: {
        candy: { query: {}, products: [] },
        stock_news: { query: {}, products: [] },
        subscription_news: { query: {}, products: [] },
        preorder_news: { query: {}, products: [] },
        last_units: { query: {}, products: [] },
        weekly_toplist: { query: {}, products: [] },
        tcg_news: { query: {}, products: [] },
        features: [],
        banners: [],
        sale_banners: [],
        popular_taxons: [],
      },
      ...opts,
    }
  );
};

export const useFetchCheckout = (opts = {}) => {
  const { locale } = useParams();
  const checkoutStore = useCheckoutStore();
  const navigate = useNavigate();
  return useAuthorizedQuery(
    ["checkout"],
    () => get(`/storefront/checkout.json?` + new URLSearchParams({ locale })),
    {
      initialData: {
        line_items: [],
        external_payment_options: [],
        payment_options: [],
        steps: { shipping: false, payment: false },
      },
      onSuccess: (res) => {
        checkoutStore.setCheckout(res);
        if (res.line_items.length === 0) navigate(`/${locale}`);
      },
      ...opts,
    }
  );
};

export const useFetchPaypalPayment = (id, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["paypal_payments", id],
    () =>
      get(
        `/storefront/paypal_payments/${id}.json?` +
        new URLSearchParams({ locale })
      ),
    opts
  );
};

export const useFetchAddress = (id, query = {}, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["addresses", { id, ...query }],
    () =>
      get(
        `/storefront/addresses/${id}.json?` +
        new URLSearchParams({ ...query, locale })
      ),
    {
      initialData: { formatted: [] },
      ...opts,
    }
  );
};

export const useFetchQuestion = (id, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["questions", locale, id],
    () =>
      get(
        `/storefront/questions/${id}.json?` + new URLSearchParams({ locale })
      ),
    opts
  );
};

export const useFetchQuestions = (opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["questions", locale],
    () => get(`/storefront/questions.json?` + new URLSearchParams({ locale })),
    {
      initialData: { questions: [] },
      ...opts,
    }
  );
};

export const useFetchSubdivisions = (country_code, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["subdivisions", country_code, locale],
    () =>
      fetch(`/${locale}/subdivisions/${country_code}.json`).then((res) => {
        if (res.ok) return res.json();
      }),
    {
      initialData: { subdivisions: [] },
      ...opts,
    }
  );
};

export const useFetchInvoice = (id, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["invoices", id],
    () =>
      get(`/storefront/invoices/${id}.json?` + new URLSearchParams({ locale })),
    opts
  );
};

export const useFetchInvoices = (query, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["invoices", { locale, ...query }],
    () =>
      get(
        `/storefront/invoices.json?` + new URLSearchParams({ locale, ...query })
      ),
    {
      initialData: { invoices: [] },
      ...opts,
    }
  );
};

export const useFetchShipment = (id, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["shipments", id],
    () =>
      get(
        `/storefront/shipments/${id}.json?` + new URLSearchParams({ locale })
      ),
    opts
  );
};

export const useFetchShipments = (query, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["shipments", { locale, ...query }],
    () =>
      get(
        `/storefront/shipments.json?` +
        new URLSearchParams({ locale, ...query })
      ),
    {
      initialData: { shipments: [], meta: { total_pages: 1 } },
      ...opts,
    }
  );
};

export const useFetchOrder = (id, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["orders", id],
    () =>
      get(`/storefront/orders/${id}.json?` + new URLSearchParams({ locale })),
    opts
  );
};

export const useFetchOrders = (query, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["orders", { locale, ...query }],
    () =>
      get(
        `/storefront/orders.json?` + new URLSearchParams({ locale, ...query })
      ),
    {
      initialData: { orders: [], meta: { total_pages: 1 } },
      ...opts,
    }
  );
};

export const useFetchAddresses = (opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["addresses", locale],
    () => get(`/storefront/addresses.json?` + new URLSearchParams({ locale })),
    {
      initialData: { addresses: [] },
      ...opts,
    }
  );
};

export const useFetchTaxonSubscriptions = (opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["taxon_subscriptions", null],
    () =>
      get(
        `/storefront/taxon_subscriptions.json?` +
        new URLSearchParams({ locale })
      ),
    {
      initialData: { taxons: [] },
      ...opts,
    }
  );
};

export const useFetchCart = (opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["cart", locale],
    () => get(`/storefront/cart.json?` + new URLSearchParams({ locale })),
    {
      initialData: {
        cart_items: [],
        suggested_products: [],
        notices: [],
        sold_out_items: [],
        preorders: [],
        discount_amount: 0,
        shipping_fee: 0,
        commodity_amount: 0,
        reduced_tax: 0,
        standard_tax: 0,
        total_amount: 0,
      },
      ...opts,
    }
  );
};

export const useFetchReservation = (id, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["reservations", id],
    () =>
      get(
        `/storefront/reservations/${id}.json?` + new URLSearchParams({ locale })
      ),
    opts
  );
};

export const useFetchWaitListItem = (id, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["wait_list_items", id],
    () =>
      get(
        `/storefront/wait_list_items/${id}.json?` +
        new URLSearchParams({ locale })
      ),
    opts
  );
};

export const useFetchWaitListItems = (query, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["wait_list_items", { locale, ...query }],
    () =>
      get(
        `/storefront/wait_list_items.json?` +
        new URLSearchParams({ locale, ...query })
      ),
    {
      ...opts,
      initialData: { wait_list_items: [], meta: { total_pages: 1 } },
    }
  );
};

export const useFetchDownPayment = (id, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["down_payments", id],
    () =>
      get(
        `/storefront/down_payments/${id}.json?` +
        new URLSearchParams({ locale })
      ),
    {
      ...opts,
    }
  );
};

export const useFetchDownPayments = (query, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["down_payments", { locale, ...query }],
    () =>
      get(
        `/storefront/down_payments.json?` +
        new URLSearchParams({ locale, ...query })
      ),
    {
      initialData: [],
      ...opts,
    }
  );
};

export const useFetchReservations = (query, opts = {}) => {
  const ui = useUiStore();
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["reservations", { locale, ...query }],
    () =>
      get(
        `/storefront/reservations.json?` +
        new URLSearchParams({ locale, ...query })
      ),
    {
      ...opts,
      initialData: { reservations: [], meta: { total_pages: 1 } },
    }
  );
};

export const useFetchReservationStatistics = (opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["reservation_statistics", locale],
    () =>
      get(
        `/storefront/reservation_statistics.json?` +
        new URLSearchParams({ locale })
      ),
    {
      initialData: [],
      ...opts,
    }
  );
};

export const useFetchReservationStatistic = (month, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["reservation_statistics", { month, locale }],
    () =>
      get(
        `/storefront/reservation_statistics/${month}.json?` +
        new URLSearchParams({ locale })
      ),
    {
      initialData: { reservations: [] },
      ...opts,
    }
  );
};

export const useFetchUserSignedIn = () => {
  const ui = useUiStore();
  return useAuthorizedQuery(
    ["signed_in"],
    () => get(`/storefront/user/signed_in.json`),

  );
};

export const useFetchUser = (query = {}, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["users", { locale, ...query }],
    () =>
      get(`/storefront/user.json?` + new URLSearchParams({ locale, ...query })),
    {
      initialData: {
        roles: [],
        reservations: [],
        shipments: [],
        preorders: [],
        down_payments: [],
        subscription_news: {
          query: {},
          products: [],
        },
      },
      ...opts,
    }
  );
};

export const useFetchWatchLists = (params = {}, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["watch_lists", params],
    () =>
      get(
        `/storefront/watch_lists.json?` +
        new URLSearchParams({ locale, ...params })
      ),
    { initialData: { watch_lists: [] }, ...opts }
  );
};

export const useFetchWatchList = (id, params = {}, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["watch_lists", { id, ...params }],
    () =>
      get(
        `/storefront/watch_lists/${id}.json?` +
        new URLSearchParams({ locale, ...params })
      ),
    {
      ...opts,
      initialData: { name: "", watch_list_entries: [] },
      ...opts,
    }
  );
};

export const useFetchSuggestions = (query, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["suggestions", { locale, ...query }],
    () =>
      get(
        `/storefront/suggestions.json?` +
        new URLSearchParams({ locale, ...query })
      ),
    {
      initialData: [],
      ...opts,
    }
  );
};

export const useFetchSearchItems = (query, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["search_items", { locale, ...query }],
    () =>
      get(
        `/storefront/search_items.json?` +
        new URLSearchParams({ locale, ...query })
      ),
    {
      enabled: query.per > 0,
      initialData: {
        questions: [],
        results: [],
        taxons: [],
        products: [],
        meta: { total_pages: 1 },
      },
      ...opts,
    }
  );
};

export const useFetchProductsByDate = (query, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["products_by_date", { locale, ...query }],
    () =>
      get(
        `/storefront/products/by_date.json?` +
        new URLSearchParams({ locale, ...query })
      ),
    {
      initialData: { products: [] },
      ...opts,
    }
  );
};

export const useFetchProductUpdatesByDate = (query, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["product_updates_by_date", { locale, ...query }],
    () =>
      get(
        `/storefront/products/updates_by_date.json?` +
        new URLSearchParams({ locale, ...query })
      ),
    {
      initialData: [],
      ...opts,
    }
  );
};

export const useFetchQueryInfo = (query, opts = {}) => {
  const { locale } = useParams();
  const params = new URLSearchParams({
    locale,
    ...omitBy(query, (val) => [null, undefined].includes(val)),
  });
  return useAuthorizedQuery(
    ["query_info", { locale, ...query }],
    () => get(`/storefront/products/info.json?` + params),
    {
      enabled: query.per > 0,
      initialData: {
        taxons: [],
        total_pages: 1,
        gross_selling_price_max: 0,
        gross_selling_price_min: 0,
      },
      ...opts,
    }
  );
};

export const useFetchInfiniteProducts = (query, opts = {}) => {
  const { locale } = useParams();
  const cleanedQuery = omitBy(query, (val) => ["", null, undefined].includes(val))
  return useInfiniteQuery({
    queryKey:
      [
        "products",
        { locale, ...cleanedQuery }
      ],
    queryFn: (ctx) => {
      const params = new URLSearchParams({
        locale,
        ...cleanedQuery,
        page: ctx.pageParam || 1,
      });
      return get(`/storefront/products.json?` + params);
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage && pages.length < lastPage.meta.total_pages)
        return pages.length + 1;
      else return undefined;
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined
      }
      return firstPageParam - 1
    },
    initialData: { pages: [], pageParams: [] },
    ...opts,
  });
};

export const useFetchProducts = (query, opts = {}) => {
  const { locale } = useParams();
  const params = new URLSearchParams({
    locale,
    ...omitBy(query, (val) => ["", null, undefined].includes(val)),
  });
  return useAuthorizedQuery(
    [
      "products",
      {
        locale,
        ...omitBy(query, (val) => ["", null, undefined].includes(val)),
      },
    ],
    () => get(`/storefront/products.json?` + params),
    {
      enabled: query.per > 0,
      initialData: {
        products: [],
        meta: {
          total_pages: 1,
          gross_selling_price_max: 0,
          gross_selling_price_min: 0,
        },
      },
      ...opts,
    }
  );
};

export const useFetchImages = (id, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["images", id],
    () =>
      get(
        `/storefront/products/${id}/images.json?` +
        new URLSearchParams({ locale })
      ),
    {
      initialData: [],
      ...opts,
    }
  );
};

export const useFetchProduct = (slug, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["products", slug],
    () =>
      get(
        `/storefront/products/${slug}.json?` + new URLSearchParams({ locale })
      ),
    {
      ...opts,
    }
  );
};

export const useFetchSale = (slug, query = { view: "show" }, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["sales", { slug, view: query.view }],
    () =>
      get(
        `/storefront/sales/${slug}.json?` +
        new URLSearchParams({ locale, ...query })
      ),
    {
      initialData: {
        title: "",
        tagline: "",
      },
      ...opts,
    }
  );
};

export const useFetchTaxon = (slug, query = { view: "show" }, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["taxons", { slug, view: query.view }],
    () =>
      get(
        `/storefront/taxons/${slug}.json?` +
        new URLSearchParams({ locale, ...query })
      ),
    {
      initialData: {
        alternates: [],
        title: "",
        tagline: "",
        description: "",
        meta_title: "",
        taxon_suggestions: [],
        filterable_taxonomies: [],
        products_by_day: [],
        taxonomy: { title: "" },
      },
      ...opts,
    }
  );
};

export const useFetchTaxonomyByTaxon = (query, opts = { enabled: true }) => {
  const { taxonId, taxonomyId } = query;
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["taxonomy_by_taxons", { locale, ...query }],
    () =>
      get(
        `/storefront/taxonomy_by_taxons/${taxonId}/${taxonomyId}.json?` +
        new URLSearchParams({ locale })
      ),
    {
      enabled: query.per && query.per > 0 && opts.enabled,
      initialData: {
        taxonomy: { title: "", slug: "" },
        taxon: { title: "", slug: "" },
        results: [],
      },
      ...opts,
    }
  );
};

export const useFetchSimilarTaxons = (query, opts = { enabled: false }) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["similar_taxons", { locale, ...query }],
    () =>
      get(
        `/storefront/similar_taxons.json?` + new URLSearchParams({ locale, ...query })
      ),
    {
      initialData: { taxons: [], meta: { total_pages: 1 } },
      ...opts,
    }
  );
};

export const useFetchTaxons = (query, opts = { enabled: true }) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["taxons", { locale, ...query }],
    () =>
      get(
        `/storefront/taxons.json?` + new URLSearchParams({ locale, ...query })
      ),
    {
      enabled: query.per && query.per > 0 && opts.enabled,
      initialData: { taxons: [], meta: { total_pages: 1 } },
      ...opts,
    }
  );
};

export const useFetchTaxonomies = (query, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["taxonomies", { locale, ...query }],
    () =>
      get(
        `/storefront/taxonomies.json?` +
        new URLSearchParams({ locale, ...query })
      ),
    {
      enabled: query.per > 0,
      initialData: { taxonomies: [], meta: { total_pages: 1 } },
      ...opts,
    }
  );
};

export const useFetchTaxonomy = (slug, query = {}, opts = {}) => {
  const { locale } = useParams();
  return useAuthorizedQuery(
    ["taxonomies", { id: slug, ...query }],
    () =>
      get(
        `/storefront/taxonomies/${slug}.json?` +
        new URLSearchParams({ locale, ...query })
      ),
    opts
  );
};
